const header = {
  // all the properties are optional - can be left empty or deleted
  homepage: 'https://hussainthaj.dev',
  title: 'HT.',
}

const about = {
  // all the properties are optional - can be left empty or deleted
  name: 'Hussain Thajutheen',
  role: 'Full-stack Serverless Engineer',
  description: `JavaScript enthusiast who enjoys working with AWS Serverless technologies.

    Currently, looking for a role where I can utilize my skills in TypeScript, React, Node, GraphQL, AWS Serverless technologies, and WordPress to create meaningful solutions.
    
    Co-founded a WordPress agency where we were among the early adopters of WordPress as a headless CMS, with React and React Native as my preferred front-end choices.`,
  resume: 'https://example.com',
  social: {
    linkedin: 'https://www.linkedin.com/in/hussain-t/',
    github: 'https://github.com/hussain-t',
    twitter: 'https://twitter.com/hussain_thaj',
  },
}

const projects = [
  // projects can be added an removed
  // if there are no projects, Projects section won't show up
  {
    name: 'Capbase',
    description: 'Senior Software Engineer | April 2019 - Present',
    stack: [
      'TypeScript',
      'React',
      'GraphQL',
      'AWS Amplify',
      'AWS AppSync',
      'AWS Lambda',
      'AWS DynamoDB',
    ],
    // livePreview: 'https://capbase.com/',
  },
  {
    name: 'Insytful',
    description: 'Partner | Febraury 2019 - Present',
    stack: ['React', 'Gatsby', 'Next.js', 'WordPress', 'GraphQL'],
    // livePreview: 'https://insytful.co/',
  },
  {
    name: 'Lacadive IO',
    description: 'Co-Founder | March 2016 - Febraury 2019',
    stack: ['React', 'React Native', 'Gatsby', 'WordPress', 'GraphQL'],
  },
  {
    name: 'Cubo Systems',
    description: 'Senior Software Engineer | September 2014 - November 2017',
    stack: ['ASP.NET', 'MySQL', 'Umbraco'],
  },
]

const skills = [
  // skills can be added or removed
  // if there are no skills, Skills section won't show up
  'JavaScript',
  'TypeScript',
  'React',
  'React Native',
  'Node.js',
  'AWS Lambda',
  'AWS Amplify',
  'AWS AppSync',
  'AWS DynamoDB',
  'AWS CDK',
  'GraphQL',
  'WordPress',
  'Jest',
]

const contact = {
  // email is optional - if left empty Contact section won't show up
  email: 'hussain@hey.com',
}

export { header, about, projects, skills, contact }
