import './Talks.css'

const Talks = () => (
  <section className='section talks' id='talks'>
    <h2 className='section__title'>Talks</h2>
    <div className='talks center'>
      <p className='talks__desc'>
        I also love to speak about React, WordPress and AWS Serverless. This is
        my latest meetup talk titled{' '}
        <a href='https://wordpress.tv/2021/10/04/hussain-thajutheen-headless-wordpress-authentication-with-react/'>
          Headless WordPress Authentication with React
        </a>{' '}
        published on <a href='https://wordpress.tv/'>WordPress.tv</a>
      </p>
    </div>
  </section>
)

export default Talks
