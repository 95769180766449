import GitHubIcon from '@material-ui/icons/GitHub'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import TwitterIcon from '@material-ui/icons/Twitter'
import { about } from '../../portfolio'

import './About.css'

const About = () => {
  const { name, role, social } = about

  return (
    <div className='about center'>
      {name && (
        <h1>
          Hi, I am <span className='about__name'>{name}.</span>
        </h1>
      )}

      {role && <h2 className='about__role'>A {role}.</h2>}
      <p className='about__desc'>
        JavaScript enthusiast who enjoys working with AWS Serverless
        technologies.
      </p>
      <p className='about__desc'>
        Co-founded a WordPress agency where we were among the early adopters of
        WordPress as a headless CMS, with React and React Native as my preferred
        front-end choices.
      </p>
      <p className='about__desc'>
        Reach out to me for contracting/full-time on TypeScript, React, Node,
        GraphQL, AWS Serverless technologies, and WordPress.
      </p>

      <div className='about__contact center'>
        {social && (
          <>
            {social.github && (
              <a
                href={social.github}
                aria-label='github'
                className='link link--icon'
              >
                <GitHubIcon />
              </a>
            )}

            {social.linkedin && (
              <a
                href={social.linkedin}
                aria-label='linkedin'
                className='link link--icon'
              >
                <LinkedInIcon />
              </a>
            )}

            {social.twitter && (
              <a
                href={social.twitter}
                aria-label='twitter'
                className='link link--icon'
              >
                <TwitterIcon />
              </a>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default About
